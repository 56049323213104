<template>
  <b-button
    variant="primary"
    size="sm"
    @click="onClick"
    class="plus-button"
  >
    +
  </b-button>
</template>

<script>
import { getClickEventIncrement } from '@/helpers';

export default {
  name: 'PlusButton',
  props: {
    value: {},
    max: {
      type: [Number, String],
    },
  },
  methods: {
    onClick(event) {
      const max = this.max === undefined ? Number.POSITIVE_INFINITY : parseInt(this.max, 10);
      const number = Math.min(max, parseInt(this.value, 10) + getClickEventIncrement(event));
      this.$emit('input', number);
    },
  },
};
</script>

<style lang="scss" scoped>
  .plus-button {
    display: block;
    width: 32px;
  }
</style>
